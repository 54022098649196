function slickGradeEnsinoCompleta() {
  if ($('#gradeEnsinoCompleta .cardsGradeEnsinoCompleta').length) {
    if (!document.querySelector('#gradeEnsinoCompleta .cardsGradeEnsinoCompleta').classList.contains('slick-initialized')) {
      $('#gradeEnsinoCompleta .cardsGradeEnsinoCompleta').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              variableWidth: true,
            },
          },
        ],
      });
    }
  }
}